body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url(https://i.imgur.com/qgPbb4N.jpeg);
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
  overflow: auto;
  height: 100%;
}

html {
  height: 100%;
}

#root {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.main-container {
  height: 100%;
  overflow-y: auto;
}

.nav {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding-top: 2rem;
  padding-bottom: 1rem;
  width: 100%;
  font-size: 2rem;
  font-family: Copperplate, Papyrus, fantasy;

}

.nav a {
  text-decoration: none;
  color: white;
}

#period {
  color: red;
}

.nav-routes {
  display:flex;
  margin: 0em;
  justify-content: space-evenly
}

.nav li {
  padding-right: 10%;
  list-style: none;
}

.nav a:hover{
  transition : all 0.4s linear;
  color:gold;
}

.home-page {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  width: 100%;
}

.home-left-split {
  width: 50%;
  height: 100%;
  padding-left: 5%;
  padding-right: 5%;
  padding-bottom: 5%;
  box-sizing: border-box;
  text-align: center;
  overflow-y: auto;
  overflow-x: auto;
}

#home-intro {
  margin-top: 4rem;
}

.home-right-split {
  width: 50%;
  box-sizing: border-box;
  height: 100%;
}

#first-name {
  font-size: 6rem;
  margin-bottom: .5rem;
  margin-top: 2rem;
  font-family: Copperplate, Papyrus, fantasy;
}

#last-name {
  font-size: 6rem;
  margin-top: .5rem;
  margin-bottom: 1rem;
  font-family: Copperplate, Papyrus, fantasy;
}

#hello-button {
  background-color: red;
  border-radius: 15px;
  width: 40%;
  font-family: Copperplate, Papyrus, fantasy;
  display: flex;
  align-items: center;
  justify-content: center
}

#hello-button a {
  font-size: 1.5rem;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

#hello-button:hover {
  transform: scale(1.1);
  transition : all 0.4s linear;
}

.profile-pic {
  display:flex;
  object-fit: cover;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  width: 100%;
}

.sml-profile-pic {
  border-radius: 50%;
  display: none;
}

.about {
  padding-top: .1%;
  box-sizing: border-box;
  height: 100%;
  font-size: 2rem;
}

.about p {
  text-align: center;
}

.about h2 {
  text-align: center;
  font-size: 3vw;
  margin-bottom: 0px;
}

.icon-links {
  display:flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin-bottom: .25%;
  margin-top: .25%;
  padding-top: 3%;
  padding-left: 0px;
}

.icon-links li {
  list-style:none;
}

.icon-links a {
  color: white;
  text-decoration: none;
}

.icon-links li a:hover{
  transition : all 0.4s linear;
  color:gold;
}

.skills {
  display:flex;
  justify-content: space-evenly;
  box-sizing: border-box;
  padding-left: 5%;
  padding-right: 5%;
  font-family: Copperplate, Papyrus, fantasy;
  width: 50%;
  position: absolute;
  bottom: 20px; 
  left: 0; 
  z-index: 10;
}

.skills li {
  list-style: none;
}

.contacts {
  text-align: center;
  font-size: 2rem;
}
.contacts h2 {
  text-align: center;
}
.contacts a {
  color:white;
  text-decoration: none;
  transition : all 0.4s linear;
}

.contacts a:hover {
  transform: scale(1.1);
  color: blue;
}

.description {
  padding-left: 15%;
  padding-right: 15%;
  text-align: center;
  max-height:inherit;
  box-sizing: border-box;
  padding-bottom: 1%;
  overflow-y: scroll;
}

.projects {
  height: 100%;
  width: 100%;
  display:flex;
  flex-direction: column;
  padding-top: 1rem;
  box-sizing: border-box;
  overflow-y: auto
}

.projects a {
  color: white;
}

.projects a:hover {
  transform: scale(1.1);
}

.projects h2 {
  text-align: center;
  font-size: 3vw;
  margin-bottom: 0px;
}

.proj-1, .proj-2 {
  width: 100%;
  display: flex;
  box-sizing: border-box;
}

.proj-1 {
  padding-bottom: 3%;
}

.proj-des {
  width: 50%;
  padding-left: 4rem;
  padding-right: 4rem;
  display: inline-block;
  font-size: 1rem;
  box-sizing:border-box;
}

.proj-des a {
  padding-right: 1rem;
}

.proj-des a:hover {
  background: rgb(0, 0, 221);
  color:white;
  transition : all 0.4s linear;
  border: 1px solid white;
  text-decoration: none;
}

.proj-visual {
  width: 50%;
  display: inline-block;
  box-sizing:border-box;
  padding-right: 4rem;
}

.proj-visual-small {
  display: none;
}

@media screen and (max-width: 519px) {
  .profile-pic { 
    display: none; 
  } 
  .home-right-split {
    display: none;
  }

  .home-left-split {
    width: 100%;
  }

  .nav {
    font-size: 1.5rem;
  }

  #first-name, #last-name {
    font-size: 3rem;
  }

  .sml-profile-pic {
    display: inline;
    width: 180px;
    height: 180px;
  }

  .proj-des {
    font-size: .7rem;
    width: 100%;
    padding-left: 2rem;
    padding-right: 2rem;
    display: inline-block;
  }

  .proj-visual {
    display: none;
  }

  .proj-visual-small {
    display: block;
    padding-top: 5%;
  }

  .about {
    font-size: 1.3rem;
  }

  .contacts {
    font-size: 1.3rem;
  }

  .skills {
    width: 100%;
  }

  #home-intro {
    margin-top: 1rem;
    font-size: 1.2rem;
  }

  .skills {
    bottom: 0px;
  }
}

@media screen and (min-width: 520px) and (max-width: 1120px) { 
  .profile-pic { 
    display: none; 
  } 
  .home-right-split {
    display: none;
  }

  .home-left-split {
    width: 100%;
  }

  #first-name, #last-name {
    font-size: 3rem;
  }

  .sml-profile-pic {
    display: inline;
    width: 180px;
    height: 180px;
  }

  .proj-des {
    font-size: .7rem;
    width: 100%;
    padding-left: 2rem;
    padding-right: 2rem;
    display: inline-block;
  }

  .proj-visual {
    display: none;
  }

  .proj-visual-small {
    display: block;
    padding-top: 5%;
  }

  .about {
    font-size: 1.3rem;
  }

  .contacts {
    font-size: 1.3rem;
  }

  .skills {
    width: 100%;
  }

  #home-intro {
    margin-top: 1rem;
  }
}

@media screen and (min-width: 1600px) {
  .proj-des {
    font-size: 1.5rem;
  }
}

@media screen and (max-height: 649px){
  .skills {
    display: none;
  }
}

@media screen and (min-height: 650px) and (max-height: 698px) {
  .icon-links {
    padding-top: 0px;
    margin-top: 0px;
  }

  #first-name {
    margin-top: .5rem
  }
}

@media screen and (min-height: 700px) and (max-height: 850px) {
  #first-name {
    margin-top: 4rem;
  }
}

@media screen and (min-height: 851px) {
  #first-name {
    margin-top: 6rem;
  }

  #home-intro {
    font-size: 2rem;
  }
}